
import { defineComponent } from 'vue';
import { store } from '../../../store/store';

export default defineComponent({
  name: 'TheCategory.vue',

  props: {
    state: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      category: '',
      categoryError: '',
      store,
      showOptions: false,
    };
  },

  beforeMount() {
    store.retieveAllLinks();
  },

  methods: {
    selectOption(item: string) {
      this.category = item;
      this.showOptions = false;
      store.setCategory(this.category);
    },

    validate() {
      this.showOptions = false;
      if (this.category.length < 3 || this.category.length > 15) {
        this.categoryError = 'Category may have between 3 and 15 characters.';
      } else {
        this.categoryError = '';
      }

      store.setCategory(this.category);
    },

    resetInput() {
      this.categoryError = '';
    },

    takeText() {
      this.category = this.state as string;
    },
  },
});
