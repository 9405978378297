
import { defineComponent } from 'vue';
import { checkPaymentNumber, upgradeUser } from '@/components/lib/account';

export default defineComponent({
  name: 'TheAccountUpgrade.vue',
  components: {},

  data() {
    return {
      key: 1,
      number: '',
    };
  },

  methods: {
    async upgradeAccount() {
      const check = await checkPaymentNumber(this.number);

      if (check === true) {
        const result = await upgradeUser(this.number);

        if (result === true) {
          alert(
            'Account successfully upgraded. Click on Ok, come back and check your Account Tariff.',
          );
          window.location.reload();
        } else {
          alert('Wrong number. Make sure that you take the number from your payment.');
        }
      }
    },
  },
});
