
import TheArchiveIcon from '@/assets/svg/TheArchiveIcon.vue';
import TheAccount from '@/components/userprofile/account/TheAccount.vue';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { defineComponent } from 'vue';
import TheCreateAndSaveNewLink from './link/TheCreateAndSaveNewLink.vue';

export default defineComponent({
  components: {
    TheCreateAndSaveNewLink,
    TheArchiveIcon,
    TheAccount,
  },

  methods: {
    openArchive() {
      this.$router.push('archive');
    },
  },
});
