
import { defineComponent } from 'vue';
import { store } from '../../../store/store';

export default defineComponent({
  name: 'LinkDescription.vue',

  props: {
    state: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      linkdescription: '',
      linkdescriptionError: '',
    };
  },

  methods: {
    validate() {
      if (this.linkdescription.length < 3 || this.linkdescription.length > 60) {
        this.linkdescriptionError = 'Link-Description may have between 3 and 60 characters.';
      } else {
        this.linkdescriptionError = '';
      }
      store.setLinkdescription(this.linkdescription);
    },

    resetInput() {
      this.linkdescriptionError = '';
    },

    takeText() {
      this.linkdescription = this.state as string;
    },
  },
});
