
import { store } from '@/store/store';
import { defineComponent } from 'vue';
import { errorToastFileUpload } from '../../toasts/toasts';
import { uploadFile } from './upload';

export default defineComponent({
  name: 'TheUploadEncryptedFiles.vue',

  props: {
    state: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      files: [] as any,
    };
  },

  methods: {
    async handleFileChange() {
      const maxSize = 150 * 1024 * 1024; // 150MB in bytes
      // @ts-ignore
      const files = this.$refs.fileInput.files;

      if (files > maxSize) {
        errorToastFileUpload();
      } else {
        store.files = files;
        await uploadFile();
      }
    },
  },
});
