
import { encryptString } from '@/components/crypto/crypto';
import swal from 'sweetalert2';
import { defineComponent } from 'vue';
import LinkIcon from '../../../assets/svg/TheLinkIcon.vue';
import { store } from '../../../store/store';
import AddBtn from '../../buttons/TheAddBtn.vue';
import CloseModalButton from '../../buttons/TheCloseModalBtn.vue';
import LoadingButton from '../../buttons/TheLoadingButton.vue';
import { getAccountSize } from '../../lib/account';
import { supabase } from '../../lib/supabaseClient';
import { errorToastFileUpload } from '../../toasts/toasts';
import TheContactEmail from '../contacts/TheContactEmail.vue';
import TheContactName from '../contacts/TheContactName.vue';
import TheContactPhoneNumber from '../contacts/TheContactPhoneNumber.vue';
import TheUploadEncryptedFiles from '../filearchive/TheUploadEncryptedFiles.vue';
import { uploadFile } from '../filearchive/upload';
import TheCategory from './TheCategory.vue';
import TheLink from './TheLink.vue';
import LinkDescription from './TheLinkDescription.vue';
import LinkName from './TheLinkName.vue';
import TheLinkNotes from './TheLinkNotes.vue';
import TheLinkPassword from './TheLinkPassword.vue';
import TheLinkUsername from './TheLinkUsername.vue';

export default defineComponent({
  name: 'CreateAndSaveNewLink.vue',
  components: {
    TheLink,
    LinkName,
    LinkDescription,
    TheCategory,
    TheLinkUsername,
    TheLinkPassword,
    LinkIcon,
    AddBtn,
    CloseModalButton,
    LoadingButton,
    TheContactName,
    TheContactPhoneNumber,
    TheContactEmail,
    TheLinkNotes,
    TheUploadEncryptedFiles,
  },

  data() {
    return {
      nBtn: true,
      loading: false,
      key: 1,
      encryptedLinkName: '',
      encryptedLinkDescription: '',
      encryptedCategory: '',
      encryptedLink: '',
      encryptedLinkUsername: '',
      encryptedLinkPassword: '',
      updateString: '',
      encryptedContactName: '',
      encryptedContactPhoneNumber: '',
      encryptedContactEmail: '',
      encryptedNotes: '',
      filename: '',
    };
  },
  methods: {
    async addNewLinkBtn() {
      this.nBtn = false;
      this.loading = true;

      if (store.linkname) {
        const encryptedLinkName = encryptString(store.linkname);
        this.encryptedLinkName = encryptedLinkName;
      }

      if (store.linkdescription) {
        const encryptedLinkDescription = encryptString(store.linkdescription);
        this.encryptedLinkDescription = encryptedLinkDescription;
      }

      if (store.category) {
        const encryptedCategory = encryptString(store.category);
        this.encryptedCategory = encryptedCategory;
      }

      if (store.link) {
        const encryptedLink = encryptString(store.link);
        this.encryptedLink = encryptedLink;
      }

      if (store.linkUsername) {
        const encryptedLinkUsername = encryptString(store.linkUsername);
        this.encryptedLinkUsername = encryptedLinkUsername;
      }

      if (store.linkPassword) {
        const encryptedPass = encryptString(store.linkPassword);
        this.encryptedLinkPassword = encryptedPass;
      }

      if (store.contactName) {
        const encryptedContactName = encryptString(store.contactName);
        this.encryptedContactName = encryptedContactName;
      }

      if (store.contactPhoneNumber) {
        const encryptedContactPhoneNumber = encryptString(store.contactPhoneNumber);
        this.encryptedContactPhoneNumber = encryptedContactPhoneNumber;
      }

      if (store.contactEmail) {
        const encryptedContactEmail = encryptString(store.contactEmail);
        this.encryptedContactEmail = encryptedContactEmail;
      }

      if (store.linkNotes) {
        const encryptedNotes = encryptString(store.linkNotes);
        this.encryptedNotes = encryptedNotes;
      }

      if (store.files && store.files.length > 0) {
        console.log(store.files);

        const maxSize = 150;
        const { mb, gb, totalSizeInMB } = await getAccountSize();

        let uploadSize = store.files[0].size / (1024 * 1024);

        const totalSizeAfterUpload = totalSizeInMB + uploadSize;

        if (totalSizeAfterUpload < maxSize) {
          const filename = await uploadFile();
          const encryptedFilename = encryptString(filename);
          this.filename = encryptedFilename;
        } else {
          this.executeCleanUp();
          errorToastFileUpload();
          return;
        }
      }

      try {
        await supabase
          .from('link')
          .insert({
            linkname: this.encryptedLinkName,
            linkdescription: this.encryptedLinkDescription,
            link: this.encryptedLink,
            category: this.encryptedCategory,
            linkusername: this.encryptedLinkUsername,
            linkpassword: this.encryptedLinkPassword,
            contactname: this.encryptedContactName,
            contactphonenumber: this.encryptedContactPhoneNumber,
            contactemail: this.encryptedContactEmail,
            notes: this.encryptedNotes,
            filename: this?.filename,
          })
          .then(() => {
            store.retieveAllLinks();
            swal
              .fire({
                icon: 'success',
                text: `You´ve successfully saved the link ${store.linkname}.`,
                timer: 1500,
                showConfirmButton: false,
              })
              .then(() => {
                this.executeCleanUp();
              });
          });
      } catch (error) {
        this.executeCleanUp();
        console.error('addNewLinkBtn Error: ' + error);
      }
    },

    executeCleanUp() {
      this.nBtn = true;
      this.loading = false;
      this.key = this.key + 1;
      store.linkname = this.updateString;
      store.linkdescription = this.updateString;
      store.link = this.updateString;
      store.category = this.updateString;
      store.linkUsername = this.updateString;
      store.linkPassword = this.updateString;
      store.contactName = this.updateString;
      store.contactPhoneNumber = this.updateString;
      store.contactEmail = this.updateString;
      store.linkNotes = this.updateString;
      store.files = [];

      this.encryptedLinkName = '';
      this.encryptedLinkDescription = '';
      this.encryptedCategory = '';
      this.encryptedLink = '';
      this.encryptedLinkUsername = '';
      this.encryptedLinkPassword = '';
      this.encryptedContactName = '';
      this.encryptedContactPhoneNumber = '';
      this.encryptedContactEmail = '';
      this.encryptedNotes = '';
      this.filename = '';
    },
  },
});
