
import { defineComponent } from 'vue';
import { appVersion } from '@/globalVariables';
import TheCopyrightIcon from '@/assets/svg/TheCopyrightIcon.vue';

export default defineComponent({
  components: { TheCopyrightIcon },

  data() {
    return {
      version: appVersion,
    };
  },
});
