
import { store } from '@/store/store';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    state: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contactUsername: '',
    };
  },

  methods: {
    set() {
      store.contactName = this.contactUsername;
    },

    takeText() {
      this.contactUsername = this.state as string;
    },
  },
});
