
import { store } from '@/store/store';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    state: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contactPhoneNumber: '',
    };
  },

  methods: {
    set() {
      store.contactPhoneNumber = this.contactPhoneNumber;
    },

    takeText() {
      this.contactPhoneNumber = this.state as string;
    },
  },
});
