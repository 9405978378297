
import { defineComponent } from 'vue';
import { store } from '../../../store/store';

export default defineComponent({
  name: 'LinkName.vue',

  props: {
    state: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      linkname: '',
      linknameError: '',
    };
  },

  methods: {
    validate() {
      if (this.linkname.length < 3 || this.linkname.length > 20) {
        this.linknameError = 'Linkname may have between 3 and 20 characters.';
      } else {
        this.linknameError = '';
      }
      store.setLinkname(this.linkname);
    },

    resetInput() {
      this.linknameError = '';
    },

    takeText() {
      this.linkname = this.state as string;
    },
  },
});
