
import { defineComponent } from 'vue';
import TheAccountIcon from '@/assets/svg/TheAccountIcon.vue';
import AddBtn from '../../buttons/TheAddBtn.vue';
import LoadingButton from '../../buttons/TheLoadingButton.vue';
import CloseModalButton from '../../buttons/TheCloseModalBtn.vue';
import TheDocuIcon from '@/assets/svg/TheDocuIcon.vue';
import TheAccountMetric from '@/components/userprofile/account/TheAccountMetric.vue';
import TheAccountUpgrade from './TheAccountUpgrade.vue';

export default defineComponent({
  name: 'TheAccount.vue',
  components: {
    TheAccountIcon,
    AddBtn,
    LoadingButton,
    CloseModalButton,
    TheDocuIcon,
    TheAccountMetric,
    TheAccountUpgrade,
  },

  data() {
    return {
      key: 1,
    };
  },
});
