
import { getAccountSize, tariffCheck } from '@/components/lib/account';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheAccountMetric.vue',

  data() {
    return {
      key: 1,
      accountMB: 0,
      accountGB: 0,
      tariff: '',
      size: '',
    };
  },

  async beforeMount() {
    const { mb, gb }: any = await getAccountSize();
    this.accountMB = mb;
    this.accountGB = gb;

    const tariff = sessionStorage.getItem('tariff') as string;
    this.tariff = tariff;

    const size = (await tariffCheck()) as string;
    this.size = size;
  },

  methods: {},
});
