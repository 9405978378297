
import { defineComponent } from 'vue';
import { store } from '../../../store/store';

export default defineComponent({
  props: {
    state: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      linkPassword: '',
    };
  },

  methods: {
    set() {
      store.setLinkPassword(this.linkPassword);
    },

    async generatePassword() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@?!';
      let generatedPassword = '';
      for (let i = 0; i < 15; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        generatedPassword += characters[randomIndex];
      }
      this.linkPassword = generatedPassword;

      this.set();
    },
  },
});
